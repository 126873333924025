<template>
  <section id="contacto" class="montserrat-regular default-padding bg-gray">
    <!--Start Container-->
    <div class="container">
      <!--Start Heading-->
      <div class="row">
        <div class="col-12 wow fadeInUp" data-wow-delay=".2s">
          <div class="heading text-center">
            <h2>CONTÁ CON NOSOTROS</h2>
            <h4 class="color-gray subtitle"></h4>
          </div>
        </div>
      </div>
      <!--End Heading-->

      <!--Start Contact Row-->
      <div class="row">
        <!--Start Contact Info-->
        <div class="contact-form-info m-0 col-lg-6">
          <ul class="contactForm">
            <li class="flex row m-0">
              <i class="icon-circle text-white fas fa-mobile-alt" />
              <div class="col">
                <div>
                  <a href="tel:39907981"> 3990-7981</a> |
                  <a href="tel:39907980">3990-7980</a> |
                  <a href="tel:37507897">3750-7897</a>
                </div>
                <div><a href="tel:+5491169761773">+54 9 11 6976-1773</a></div>
              </div>
            </li>
            <li class="flex row">
              <i class="icon-circle text-white fas fa-map-marker-alt" />
              <div class="col">
                <div>
                  Avenida Dorrego 1789 - Piso 3 Oficina 305 (C1414CKM) C.A.B.A
                </div>
                <div>Gral. Rodriguez 761 - 4 ”A” (B1842DIO) Monte. Grande.</div>
              </div>
            </li>
            <li class="flex row">
              <i class="icon-circle text-white fas fa-envelope" />
              <div class="col">
                <a href="mailto:info@zamabroker.com.ar">
                  <div>info@zamabroker.com.ar</div>
                </a>
              </div>
            </li>
            <li class="flex row">
              <div class="col-md-5 p-0">
                <div class="row flex align-items-center">
                  <a
                    href="https://www.linkedin.com/in/zama-broker-28bb2815b/"
                    target="_blank"
                  >
                    <i class="icon-circle text-white fab fa-linkedin-in" />
                  </a>
                  <a
                    href="https://www.instagram.com/zama.broker/"
                    target="_blank"
                  >
                    <i class="icon-circle text-white fab fa-instagram" />
                  </a>
                  <a
                    href="https://www.facebook.com/zama.broker"
                    target="_blank"
                  >
                    <i
                      class="icon-circle text-white fab fa-facebook-f"
                      target="_blank"
                    />
                  </a>
                  <!-- <div class="col">
                    <div>@zona broker</div>
                  </div> -->
                </div>
              </div>
              <!-- <div class="col-md-7 p-0">
                <div class="row flex align-items-center">
                  <div class="col">
                    <div>@zona.broker</div>
                  </div>
                </div>
              </div> -->
            </li>
          </ul>
        </div>
        <!--End Contact Info-->
        <!--<div class="col-lg-3"></div>-->
        <!--Start Contact Form-->
        <div class="col-lg-6 wow fadeInUp" data-wow-delay=".6s">
          <div class="contact-form">
            <form
              id="form"
              @submit.prevent="contactForm"
              method="post"
              action="sendmail.php"
              name="form"
            >
              <!-- <input type="hidden" value="Contacto" name="dzToDo" >-->
              <div class="form-group">
                <input
                  v-model="form.name"
                  class="form-control"
                  type="text"
                  name="name"
                  placeholder="Nombre:"
                  onfocus="this.value=''"
                  required
                />
              </div>
              <div class="form-group">
                <input
                  v-model="form.email"
                  class="form-control"
                  type="email"
                  name="mail"
                  placeholder="Mail:"
                  onfocus="this.value=''"
                  required
                />
              </div>
              <div class="form-group">
                <input
                  v-model="form.phone"
                  type="text"
                  class="form-control"
                  placeholder="Tel. de contacto:"
                  id="tel"
                  name="tel"
                  required
                />
              </div>
              <div class="form-group">
                <select
                  v-model="form.option"
                  name="cases"
                  required
                  class="form-control"
                  id="cases"
                >
                  <option value="">Opciones de asunto</option>
                  <option value="Necesito denunciar un siniestro.">
                    Necesito denunciar un siniestro.
                  </option>
                  <option value="Quiero cotizar un Seguro.">
                    Quiero cotizar un Seguro.
                  </option>
                  <option value="Necesito asesorarme. ">
                    Necesito asesorarme.
                  </option>
                  <option value="Quiero consultar sobre mi póliza.">
                    Quiero consultar sobre mi póliza.
                  </option>
                  <option
                    value="Necesito imprimir la documentación de mi póliza."
                  >
                    Necesito imprimir la documentación de mi póliza.
                  </option>
                </select>
              </div>
              <div class="form-group">
                <textarea
                  v-model="form.message"
                  class="form-control"
                  rows="10"
                  placeholder="Dejanos tu consulta"
                  name="message"
                  onfocus="this.value=''"
                  required
                ></textarea>
              </div>

              <div class="contact-btn text-center mt-3">
                <!--<button href="#" onclick="document.getElementById('form').submit()">
								Enviar
								</button>-->
                <input class="submit-button" type="submit" value="ENVIAR" />
              </div>
            </form>
            <div id="form-messages"></div>
          </div>
        </div>
        <!--End Contact Form-->
        <!--<div class="col-lg-3"></div>-->
      </div>
      <!--End Contact Row-->
    </div>
    <!--End Container-->
  </section>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {
        name: "",
        email: "",
        phone: "",
        message: "",
        option: "",
      },
    };
  },
  methods: {
    contactForm() {
      console.log(this.form);
      const formData = new FormData();
      let vm = this;
      const data = Object.assign({}, this.form);

      formData.append("name", data.name);
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("message", data.message);
      formData.append("option", data.option);
      formData.append("type_form", 1);

      var config = {
        method: "post",
        url: "https://www.zamabroker.com.ar/sendmailtest.php",
        data: formData,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          console.log(this.form);
        })
        .catch(function (error) {
          console.log(error);
        });
      vm.$swal.fire("Gracias", "Por contactarte con nosotros. Te responderemos a la brevedad.", "success");
    },
  },
};
</script>

<style>
.contactForm i {
  background: black;
  text-align: center;
  line-height: 50px;
  font-size: 30px;
}
.contactForm li {
  margin: 50px 0;
  align-items: center;
}
.icon-circle {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin: 5px;
}
</style>
