<template>
  <div>
    <ContactForm />
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: "Producers",
  components: {
    ContactForm,
  },
  data() {
    return {
      mainTitle: {
        first: "NUESTROS",
        second: "PRODUCTORES",
      },
      secondSection: {
        title: "SOMOS UN BROKER QUE RESPONDE",
        text: "Con productores presentes, conectados, rapidos y profesionales",
      },
    };
  },
};
</script>
